import './App.css';
import globeImage from './assets/gtech.svg'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={globeImage} className="App-logo" alt="logo" />
        <p>
          Select the assessment program you are assigned to.
        </p>
          <div className='flex gap-x-5 pt-3'>
            <button className='w-32 h-10 text-white rounded' style={{backgroundColor: '#0063d5'}} onClick={()=>{
              window.location.href = 'https://globetechcadetship-cloud.apperlabs.com/'
            }}>
              TECH
            </button>
            <button className='w-32 h-10 text-white rounded' style={{backgroundColor: '#0063d5'}} onClick={()=>{
              window.location.href = 'https://globetechcadetship-sap.apperlabs.com/'
            }}>
              SAP
            </button>
          </div>
      </header>
    </div>
  );
}

export default App;
